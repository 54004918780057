import React, { Suspense } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Loader from 'components/Progress/Loader';
import CustomTabPanel from 'components/@common/CustomTabPanel';
import { useParams } from 'react-router-dom';
import { DetailsTab } from 'components/InitiativeTabs/DetailsTab';
import ActivitiesTab from 'components/InitiativeTabs/ActivitiesTab';
import NewsTab from 'components/InitiativeTabs/NewsTab';
import NetworkTab from 'components/InitiativeTabs/NetworkTab/NetworkTab';
import TrainingTab from 'components/InitiativeTabs/TrainingTab';
import LiteratureTab from 'components/InitiativeTabs/LiteratureTab';
import LinkedInitiativesTab from 'components/InitiativeTabs/LinkedInitiativesTab';
import { fieldConfig } from './schema';
import { initiativeTabs, visiblityItemsObj } from 'utils/constants/initiatives';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';
import { useGetInitiativeMembersQuery, useGetInitiativesByIdQuery, useInitiativesTabsCountQuery } from 'store/rtk-query/initiativesApi';
import { TabsComponent } from 'components/@common';
import useTabsData from 'hooks/useTabsData';
import { isAdministrator } from 'utils/utils';
import { RESPONSE } from 'utils/constants/globalConstant';

export const CountText = styled(Box)(({ theme, disabled }) => ({
  visibility: disabled && 'hidden',
  borderRadius: '50%',
  background: theme.palette.secondary.cardBackground,
  height: '25px',
  width: '25px',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: theme.palette.primary.main,
  [theme.breakpoints.down('lg')]: {
    fontSize: '10px',
    height: '18px',
    width: '18px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '8px',
    height: '14px',
    width: '14px'
  }
}));

const InitiativeOverview = () => {
  const theme = useTheme();
  const { initiativeId, tabValue = initiativeTabs[0].value } = useParams();
  const { data, isLoading: initiativesTabsLoading } = useInitiativesTabsCountQuery(initiativeId, {
    skip: !initiativeId,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  });
  const tabsData = useTabsData(fieldConfig, data, initiativesTabsLoading);
  const {
    data: { uuid: userId = '' }
  } = useGetUserInfoQuery();

  const { data: initiativeDetails, error } = useGetInitiativesByIdQuery(
    { userId, initiativeId },
    {
      skip: !initiativeId || !userId
    }
  );

  const { data: initiativeMembers } = useGetInitiativeMembersQuery(initiativeId, { skip: !initiativeId });

  const hideCreateContributionButton =
    initiativeDetails?.visibility === visiblityItemsObj.PRIVATE &&
    !isAdministrator(initiativeDetails, userId) &&
    !initiativeMembers?.some(({ uuid }) => uuid === userId) &&
    initiativeDetails?.userInfo?.uuid !== userId;

  const isDisableOtherTabs = error?.data?.statusCode === RESPONSE.ERROR.FORBIDDEN;

  return (
    <Suspense fallback={<Loader />}>
      <Box sx={{ position: 'sticky', top: { xs: '0', md: '72px' }, background: 'white', zIndex: 99 }}>
        <>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontFamily: `'Poppins', sans-serif`,
              fontSize: 24,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              padding: '12px 5px',
              position: 'sticky',
              top: '68px'
            }}
          >
            {initiativeDetails?.title || 'Anonymous'}
          </Typography>
        </>
        <TabsComponent
          tabValue={tabValue}
          data={tabsData}
          redirectTo={`/initiatives/${initiativeId}`}
          tabsData={initiativeTabs}
          disabledFields={isDisableOtherTabs}
        />
      </Box>
      <CustomTabPanel value={tabValue} index={initiativeTabs[0].value}>
        <DetailsTab />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={initiativeTabs[1].value}>
        <ActivitiesTab initiativeId={initiativeId} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={initiativeTabs[2].value}>
        <NewsTab showCreateButton={!hideCreateContributionButton} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={initiativeTabs[3].value}>
        <LiteratureTab />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={initiativeTabs[4].value}>
        <NetworkTab />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={initiativeTabs[5].value}>
        <LinkedInitiativesTab initiativeInfo={initiativeDetails} userId={userId} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={initiativeTabs[6].value}>
        <TrainingTab />
      </CustomTabPanel>
    </Suspense>
  );
};

export default InitiativeOverview;
