import { createApi, defaultSerializeQueryArgs } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { RtkBaseQuery } from '../../utils/request';
import queryTags from 'utils/constants/queryTags';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';
import queryEndPoints from 'utils/queryEndPoints';
import { reactionType } from 'utils/constants/initiatives';
import { createUserName } from 'utils/utils';

export const initiativesApi = createApi({
  reducerPath: 'initiativesApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [
    queryTags.allInitiatives,
    queryTags.initiativesUsers,
    queryTags.initiativeById,
    queryTags.initiativeMembers,
    queryTags.getAllInitiativeComments,
    queryTags.initiativeActivityLogs,
    queryTags.initiativesTabsCount,
    queryTags.initiativeReactions,
    queryTags.getAllLinkedinInitiative
  ],
  endpoints: (builder) => ({
    getAllInitiatives: builder.query({
      query: ({
        userId,
        offset,
        limit,
        initiativeState,
        initiativeType,
        filterKeywords,
        networkType,
        relatedToMe,
        relatedToInitiative,
        searchReactor,
        sortIn,
        sortBy
      }) =>
        queryEndPoints.getAllInitiatives(
          userId,
          offset,
          limit,
          initiativeState,
          initiativeType,
          filterKeywords,
          networkType,
          relatedToMe,
          relatedToInitiative,
          searchReactor,
          sortIn,
          sortBy
        ),
      transformResponse: (response) => response?.data?.data,
      serializeQueryArgs: ({ endpointName }) => endpointName, // caching key override here
      merge: (currentCache, newItems, { arg }) => {
        const { offset } = arg;
        if (offset == 0) {
          currentCache.resultList = newItems.resultList;
          currentCache.totalCount = newItems.totalCount;
          return;
        }
        currentCache.resultList.push(...newItems.resultList);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        // checking if query arguments has changed
        const checkRefetchCondition =
          currentArg.limit !== previousArg?.limit ||
          currentArg.offset !== previousArg?.offset ||
          currentArg?.initiativeState !== previousArg?.initiativeState ||
          currentArg?.initiativeType !== previousArg?.initiativeType ||
          currentArg?.filterKeywords !== previousArg?.filterKeywords ||
          currentArg?.relatedToMe !== previousArg?.relatedToMe ||
          currentArg?.sortBy !== previousArg?.sortBy ||
          currentArg?.sortIn !== previousArg?.sortIn ||
          currentArg?.relatedToMe !== previousArg?.relatedToMe ||
          currentArg?.relatedToInitiative !== previousArg?.relatedToInitiative;
        return checkRefetchCondition;
      },
      providesTags: [queryTags.allInitiatives]
    }),
    getInitiativesById: builder.query({
      query: ({ userId, initiativeId }) => queryEndPoints.getInitiativesById(userId, initiativeId),
      providesTags: [queryTags.initiativeById],
      transformResponse: (response) => response?.data?.data
    }),
    getAllUser: builder.query({
      query: ({ offset, limit, search }) => queryEndPoints.getAllSearchUser(offset, limit, search),
      keepUnusedDataFor: 2 * 60 * 1000, // 2 minutes cache time
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.initiativesUsers]
    }),
    addInitiatives: builder.mutation({
      query: ({ body, userId }) => {
        return {
          url: queryEndPoints.addInitiatives(userId),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      transformResponse: (response) => response?.data?.data,
      invalidatesTags: [queryTags.allInitiatives]
    }),
    updateInitiatives: builder.mutation({
      query: ({ initiativeId, body, userId }) => {
        return {
          url: queryEndPoints.updateInitiatives(userId, initiativeId),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      invalidatesTags: [queryTags.initiativesTabsCount],
      async onQueryStarted({ initiativeId, userId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const updatedInitiative = data?.data?.data;
        // Step-1 Update in single initiative details
        dispatch(
          initiativesApi.util.updateQueryData(
            'getInitiativesById',
            { initiativeId, userId },
            // eslint-disable-next-line no-unused-vars
            (prev) => updatedInitiative
          )
        );
        // Step-2 Update in all initiatives
        dispatch(
          initiativesApi.util.updateQueryData('getAllInitiatives', undefined, (prev) => {
            const currentAllInitiatives = prev.resultList;
            // Find the initiative in the resultList array based on initiativeId
            const initiativeIndex = currentAllInitiatives.findIndex((item) => item.uuid === initiativeId);
            if (initiativeIndex !== -1) {
              // If the initiative exists, update it
              currentAllInitiatives[initiativeIndex] = updatedInitiative;
            } else {
              // If the initiative doesn't exist, add it to the beginning of the array
              currentAllInitiatives.unshift(updatedInitiative);
            }
          })
        );
        // Step-3 update initiative activity count
        dispatch(
          initiativesApi.util.updateQueryData('initiativesTabsCount', initiativeId, (prev) => {
            prev.activities += 1;
          })
        );
      }
    }),
    addInitiativesReaction: builder.mutation({
      query: ({ body, initiativeId }) => {
        return {
          url: queryEndPoints.addInitiativesReaction(initiativeId),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      async onQueryStarted({ initiativeId, currentInitiativeId, body: { type } }, { dispatch, queryFulfilled }) {
        // Step 1 - Optimistic updates on reaction in all initiatives
        const updateReactionOptimistically = dispatch(
          initiativesApi.util.updateQueryData('getAllInitiatives', undefined, (prev) => {
            const currentInitiatives = prev.resultList ?? [];
            // Find the item in resultList based on some condition, for example, initiative uuid
            const initiativeIndex = currentInitiatives.findIndex((item) => item.uuid === initiativeId);
            if (initiativeIndex !== -1) {
              const preLikeReaction = currentInitiatives[initiativeIndex].userHasLiked;
              const preDisLikeReaction = currentInitiatives[initiativeIndex].userHasDisliked;
              let updatedInitiative = { ...currentInitiatives[initiativeIndex] };
              if (type === reactionType.LIKE) {
                if (preLikeReaction) {
                  updatedInitiative.userHasLiked = false;
                  updatedInitiative.likeCount -= 1;
                } else {
                  updatedInitiative.userHasLiked = true;
                  updatedInitiative.likeCount += 1;
                  if (preDisLikeReaction) {
                    updatedInitiative.userHasDisliked = false;
                    updatedInitiative.dislikeCount -= 1;
                  }
                }
              } else if (type === reactionType.DISLIKE) {
                if (preDisLikeReaction) {
                  updatedInitiative.userHasDisliked = false;
                  updatedInitiative.dislikeCount -= 1;
                } else {
                  updatedInitiative.userHasDisliked = true;
                  updatedInitiative.dislikeCount += 1;
                  if (preLikeReaction) {
                    updatedInitiative.userHasLiked = false;
                    updatedInitiative.likeCount -= 1;
                  }
                }
              }
              currentInitiatives[initiativeIndex] = updatedInitiative;
            }
          })
        );

        // Step 2 - Update reaction in a single initiative
        const updateReactionOptimisticallyInSingleInitiative = dispatch(
          initiativesApi.util.updateQueryData('getInitiativeReactions', initiativeId, (draft) => {
            const preLikeReaction = draft.userHasLiked;
            const preDisLikeReaction = draft.userHasDisliked;
            let updatedReaction = { ...draft };
            if (type === reactionType.LIKE) {
              if (preLikeReaction) {
                updatedReaction.userHasLiked = false;
                updatedReaction.likeCount -= 1;
              } else {
                updatedReaction.userHasLiked = true;
                updatedReaction.likeCount += 1;
                if (preDisLikeReaction) {
                  updatedReaction.userHasDisliked = false;
                  updatedReaction.dislikeCount -= 1;
                }
              }
            } else if (type === reactionType.DISLIKE) {
              if (preDisLikeReaction) {
                updatedReaction.userHasDisliked = false;
                updatedReaction.dislikeCount -= 1;
              } else {
                updatedReaction.userHasDisliked = true;
                updatedReaction.dislikeCount += 1;
                if (preLikeReaction) {
                  updatedReaction.userHasLiked = false;
                  updatedReaction.likeCount -= 1;
                }
              }
            }
            return updatedReaction;
          })
        );

        const updateReactionOptimisticallyInLinkedInitiatives = dispatch(
          initiativesApi.util.updateQueryData('getAllLinkedinInitiative', { initiativeId: currentInitiativeId }, (prev) => {
            const currentInitiatives = prev.resultList ?? [];
            // Find the item in resultList based on some condition, for example, initiative uuid
            const initiativeIndex = currentInitiatives.findIndex((item) => item.uuid === initiativeId);
            if (initiativeIndex !== -1) {
              const preLikeReaction = currentInitiatives[initiativeIndex].userHasLiked;
              const preDisLikeReaction = currentInitiatives[initiativeIndex].userHasDisliked;
              let updatedInitiative = { ...currentInitiatives[initiativeIndex] };
              if (type === reactionType.LIKE) {
                if (preLikeReaction) {
                  updatedInitiative.userHasLiked = false;
                  updatedInitiative.likeCount -= 1;
                } else {
                  updatedInitiative.userHasLiked = true;
                  updatedInitiative.likeCount += 1;
                  if (preDisLikeReaction) {
                    updatedInitiative.userHasDisliked = false;
                    updatedInitiative.dislikeCount -= 1;
                  }
                }
              }
              currentInitiatives[initiativeIndex] = updatedInitiative;
            }
          })
        );

        // Step-3 update initiative activity count when reaction type is like
        if (type === reactionType.LIKE) {
          dispatch(
            initiativesApi.util.updateQueryData('initiativesTabsCount', initiativeId, (prev) => {
              prev.activities += 1;
            })
          );
        }

        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(error?.message || 'Failed to add initiatives reactions !');
          updateReactionOptimistically.undo();
          updateReactionOptimisticallyInSingleInitiative.undo();
          updateReactionOptimisticallyInLinkedInitiatives.undo();
        }
      }
    }),
    getInitiativeMembers: builder.query({
      query: (initiativeId) => queryEndPoints.getInitiativeMemberByIntiativeId(initiativeId),
      keepUnusedDataFor: 5 * 60 * 1000, // 5 minutes cache time
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.initiativeMembers]
    }),
    getInitiativeAdministrators: builder.query({
      query: (initiativeId) => queryEndPoints.getInitiativeAdminsByIntiativeId(initiativeId),
      keepUnusedDataFor: 5 * 60 * 1000, // 5 minutes cache time
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.initiativeAdministrators]
    }),
    getInitiativeOwner: builder.query({
      query: (initiativeId) => queryEndPoints.getInitiativeOwnerByIntiativeId(initiativeId),
      keepUnusedDataFor: 5 * 60 * 1000, // 5 minutes cache time
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.initiativeOwner]
    }),
    getInitiativeReactions: builder.query({
      query: (initiativeId) => queryEndPoints.getInitiativeReactionsByIntiativeId(initiativeId),
      keepUnusedDataFor: 5 * 60 * 1000, // 5 minutes cache time
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.initiativeReactions]
    }),
    deleteInitiativeMember: builder.mutation({
      query: ({ initiativeId, memberUserId }) => ({
        url: queryEndPoints.deleteInitiativeMemberByIntiativeId(initiativeId, memberUserId),
        method: API_REQUEST_METHOD.DELETE
      }),
      transformResponse: (response) => response?.data?.data,
      async onQueryStarted({ initiativeId, memberUserId }, { dispatch, queryFulfilled }) {
        const deleteInitiativeMember = dispatch(
          initiativesApi.util.updateQueryData(
            'getInitiativeMembers',
            initiativeId,
            (draft) => {
              return draft.filter((member) => member.uuid !== memberUserId);
            },
            true
          )
        );
        dispatch(
          initiativesApi.util.updateQueryData('initiativesTabsCount', initiativeId, (prev) => {
            prev.activities += 1;
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          toast(error?.message || 'Failed to delete member from initiative !');
          deleteInitiativeMember.undo();
        }
      }
    }),
    getAllInitiativeComments: builder.query({
      query: ({ initiativeId, offset, limit }) => queryEndPoints.getAllInitiativeComments(initiativeId, offset, limit),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { initiativeId } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: { initiativeId }
        });
      },
      merge: (currentCache, newItems, { arg }) => {
        const { offset } = arg;
        if (offset == 0) {
          currentCache.resultList = newItems.resultList;
          currentCache.totalCount = newItems.totalCount;
          return;
        }
        currentCache.resultList.push(...newItems.resultList);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        // checking if query arguments has changed
        const checkRefetchCondtion =
          currentArg.limit !== previousArg?.limit ||
          currentArg.offset !== previousArg?.offset ||
          currentArg?.initiativeId !== previousArg?.initiativeId;
        return checkRefetchCondtion;
      },
      transformResponse: (response) => response?.data?.data,
      transformErrorResponse: (response) => response?.data,
      providesTags: [queryTags.getAllInitiativeComments]
    }),
    addInitiativeComment: builder.mutation({
      query: ({ body, initiativeId }) => {
        return {
          url: queryEndPoints.addInitiativeComment({ initiativeId }),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      transformResponse: (response) => response?.data?.data,
      async onQueryStarted({ initiativeId }, { dispatch, queryFulfilled }) {
        try {
          const { data: newAddedComment } = await queryFulfilled;
          dispatch(
            initiativesApi.util.updateQueryData('getAllInitiativeComments', { initiativeId }, (draft) => {
              const currentAllComments = draft.resultList;
              currentAllComments.unshift(newAddedComment);
              draft.totalCount += 1;
            })
          );
          // Step-2 update initiative activity count
          dispatch(
            initiativesApi.util.updateQueryData('initiativesTabsCount', initiativeId, (prev) => {
              prev.activities += 1;
            })
          );
        } catch (error) {
          toast(error?.message || 'Failed to add comment !');
        }
      }
    }),
    deleteInitiativeComment: builder.mutation({
      query: (params) => ({
        url: queryEndPoints.deleteInitiativeComments(params),
        method: API_REQUEST_METHOD.DELETE
      }),
      transformResponse: (response) => response?.data?.data,
      async onQueryStarted({ initiativeId, commentId }, { dispatch, queryFulfilled }) {
        const deleteInitiativeComment = dispatch(
          initiativesApi.util.updateQueryData(
            'getAllInitiativeComments',
            { initiativeId },
            (draft) => {
              draft.resultList = draft.resultList.filter((comment) => comment.id !== commentId);
              draft.totalCount -= 1;
            },
            true
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          toast(error?.message || 'Failed to delete comment from initiative !');
          deleteInitiativeComment.undo();
        }
      }
    }),
    updateInitiativeComments: builder.mutation({
      query: ({ body, initiativeId, commentId }) => ({
        url: queryEndPoints.updateInitiativeComments({ initiativeId, commentId }),
        method: API_REQUEST_METHOD.PATCH,
        body
      }),
      transformResponse: (response) => response?.data?.data,
      invalidatesTags: [queryTags.getAllInitiativeComments]
    }),
    joinInitiativesRequest: builder.query({
      query: (initiativeId) => queryEndPoints.joinInitiativesRequest(initiativeId)
    }),
    joinInitiativesStatus: builder.query({
      query: ({ initiativeId, doorKnockId, status }) => queryEndPoints.joinInitiativesStatus(initiativeId, doorKnockId, status),
      transformErrorResponse: (response) => response?.data
    }),
    initiativesTabsCount: builder.query({
      query: (initiativeId) => queryEndPoints.getAllInitiativesTabsCount(initiativeId),
      transformResponse: (response) => response?.data,
      providesTags: [queryTags.initiativesTabsCount]
    }),
    // activity logs
    getInitiativeActivityLogs: builder.query({
      query: ({ initiativeId, offset, limit }) => queryEndPoints.getInitiativeActivityLogs({ initiativeId, offset, limit }),
      transformResponse: (response) => response?.data?.data,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { initiativeId } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: { initiativeId }
        });
      },
      merge: (currentCache, newItems, { arg }) => {
        const { offset } = arg;
        if (offset == 0) {
          currentCache.resultList = newItems.resultList;
          currentCache.totalCount = newItems.totalCount;
          return;
        }
        currentCache.resultList.push(...newItems.resultList);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        // checking if query arguments has changed
        const checkRefetchCondtion =
          currentArg.limit !== previousArg?.limit ||
          currentArg.offset !== previousArg?.offset ||
          currentArg?.initiativeId !== previousArg?.initiativeId;
        return checkRefetchCondtion;
      },
      providesTags: [queryTags.initiativeActivityLogs]
    }),
    userMessageToOwner: builder.mutation({
      query: ({ body, initiativeId }) => ({
        url: queryEndPoints.userMessageToOwner(initiativeId),
        method: API_REQUEST_METHOD.POST,
        body
      }),
      transformResponse: (response) => response?.data?.data,
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          toast.success('Message sent to owner !');
        } catch (error) {
          toast.error(error?.message || 'Failed to send message to owner !');
        }
      }
    }),
    addWatchedInitiatives: builder.mutation({
      query: (initiativeId) => {
        return {
          url: queryEndPoints.addWatchedInitiatives(initiativeId),
          method: API_REQUEST_METHOD.POST
        };
      },
      async onQueryStarted(initiativeId, { queryFulfilled, dispatch }) {
        const patchResult = dispatch(
          initiativesApi.util.updateQueryData('getAllInitiatives', undefined, (prev) => {
            const currentAllInitiatives = prev?.resultList;
            const newAllInitiatives = currentAllInitiatives?.map((initiative) => {
              if (initiative?.uuid === initiativeId) {
                return { ...initiative, isInWatchedList: true };
              }
              return initiative;
            });
            return { ...prev, resultList: newAllInitiatives };
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          toast.error(error?.message || 'Failed to remove initiative from watch list !');
        }
      }
    }),
    removeWatchedInitiatives: builder.mutation({
      query: ({ initiativeId }) => {
        return {
          url: queryEndPoints.removeWatchedInitiatives(initiativeId),
          method: API_REQUEST_METHOD.DELETE
        };
      },
      async onQueryStarted({ initiativeId, removeFromList }, { queryFulfilled, dispatch }) {
        if (!removeFromList) return;
        const patchResult = dispatch(
          initiativesApi.util.updateQueryData('getAllInitiatives', undefined, (prev) => {
            const currentAllInitiatives = prev?.resultList;
            const newAllInitiatives = currentAllInitiatives?.filter((initiative) => initiative?.uuid !== initiativeId);
            return { ...prev, resultList: newAllInitiatives, totalCount: newAllInitiatives.length };
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
          toast.error(error?.message || 'Failed to remove initiative from watch list !');
        }
      }
    }),
    getAllLinkedinInitiative: builder.query({
      query: ({ initiativeId, offset, limit }) => queryEndPoints.getAllLinkedinInitiative({ initiativeId, offset, limit }),
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.getAllLinkedinInitiative],
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { initiativeId } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: { initiativeId }
        });
      },
      merge: (currentCache, newItems, { arg }) => {
        const { offset } = arg;
        if (offset == 0) {
          currentCache.resultList = newItems.resultList;
          currentCache.totalCount = newItems.totalCount;
          return;
        }
        currentCache.resultList.push(...newItems.resultList);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        // checking if query arguments has changed
        const checkRefetchCondtion =
          currentArg.limit !== previousArg?.limit ||
          currentArg.offset !== previousArg?.offset ||
          currentArg?.initiativeId !== previousArg?.initiativeId;
        return checkRefetchCondtion;
      }
    }),
    linkedAnInitiative: builder.mutation({
      query: ({ initiativeId, linkedInitiativeId }) => ({
        url: queryEndPoints.linkedAnInitiative({ initiativeId, linkedInitiativeId }),
        method: API_REQUEST_METHOD.POST
      }),
      transformResponse: (response) => response?.data?.data,
      async onQueryStarted({ initiativeId, initiativeInfo }, { dispatch, queryFulfilled }) {
        try {
          let linkedInitiativeData;
          const { data } = await queryFulfilled;
          if (initiativeInfo) {
            linkedInitiativeData = { ...initiativeInfo };
          } else {
            linkedInitiativeData = data;
          }
          dispatch(
            initiativesApi.util.updateQueryData('getAllLinkedinInitiative', { initiativeId }, (draft) => {
              draft.resultList.unshift(linkedInitiativeData);
            })
          );
          dispatch(
            initiativesApi.util.updateQueryData('initiativesTabsCount', initiativeId, (prev) => {
              prev.linkedInitiatives += 1;
            })
          );
          toast.success('Linked Initiative Successfully !');
        } catch (error) {
          toast.error(error?.message || 'Failed to link initiative!');
        }
      }
    }),

    unLinkedAnInitiative: builder.mutation({
      query: ({ initiativeId, linkedInitiativeId }) => ({
        url: queryEndPoints.unLinkedAnInitiative({ initiativeId, linkedInitiativeId }),
        method: API_REQUEST_METHOD.DELETE
      }),
      transformResponse: (response) => response?.data?.data,
      async onQueryStarted({ initiativeId, linkedInitiativeId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          initiativesApi.util.updateQueryData('getAllLinkedinInitiative', { initiativeId }, (draft) => {
            const index = draft.resultList.findIndex((item) => item.uuid === linkedInitiativeId);
            if (index !== -1) {
              draft.resultList.splice(index, 1);
            }
          })
        );
        dispatch(
          initiativesApi.util.updateQueryData('initiativesTabsCount', initiativeId, (prev) => {
            prev.linkedInitiatives -= 1;
          })
        );
        try {
          await queryFulfilled;
          toast.success('UnLinked Initiative Successfully !');
        } catch (error) {
          toast.error(error?.message || 'Failed to unlink initiative!');
          patchResult.undo();
        }
      }
    }),
    getRelatedInitiatives: builder.query({
      query: ({ initiativeId, offset, limit }) => queryEndPoints.getAllRelatedinInitiative({ initiativeId, offset, limit }),
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.getAllRelatedinInitiative]
    }),
    getRecommendedNetwork: builder.query({
      query: (initiativeId) => queryEndPoints.getRecommendedNetwork(initiativeId),
      transformResponse: (response) => {
        const apiResponse = response?.data?.data || [];
        return {
          recommendationUsersList:
            apiResponse?.users?.length > 0
              ? apiResponse?.users?.map((user) => {
                  const userEntity = user?.entity;
                  return {
                    name: createUserName(userEntity),
                    matchingPercentage: user?.matchingPercentage,
                    matchedKeywords: user?.matchedKeywords,
                    avatarUrl: userEntity?.profileImage,
                    uuid: userEntity?.uuid,
                    networkType: 'user'
                  };
                })
              : [],
          recommendationOrganizationList:
            apiResponse?.enterprises?.length > 0
              ? apiResponse?.enterprises?.map((enterprise) => {
                  const enterpriseEntity = enterprise?.entity;
                  return {
                    name: enterpriseEntity?.name,
                    matchingPercentage: enterprise?.matchingPercentage,
                    matchedKeywords: enterprise?.matchedKeywords,
                    avatarUrl: enterpriseEntity?.profileImage,
                    uuid: enterpriseEntity?.uuid,
                    networkType: 'enterprise'
                  };
                })
              : []
        };
      },
      providesTags: [queryTags.getRecommendedNetwork]
    }),
    getMyInitiatives: builder.query({
      query: ({
        userId,
        offset,
        limit,
        initiativeState,
        initiativeType,
        filterKeywords,
        networkType,
        relatedToMe,
        relatedToInitiative = '',
        searchReactor = '',
        sortIn,
        sortBy
      }) =>
        queryEndPoints.getAllInitiatives(
          userId,
          offset,
          limit,
          initiativeState,
          initiativeType,
          filterKeywords,
          networkType,
          relatedToMe,
          relatedToInitiative,
          searchReactor,
          sortIn,
          sortBy
        ),
      transformResponse: (response) => {
        return response?.data?.data?.resultList;
      }
    }),
    leaveInitiative: builder.mutation({
      query: (initiativeId) => ({
        url: queryEndPoints.leaveInitiative(initiativeId),
        method: API_REQUEST_METHOD.POST
      })
    }),
    checkInitiativeInWatchedList: builder.query({
      query: (initiativeId) => queryEndPoints.checkInitiativeInWatchedList(initiativeId)
    }),
    checkUserInAuthority: builder.query({
      query: (initiativeId) => queryEndPoints.checkUserInAuthority(initiativeId)
    }),
    generateMatchingMatrixForInitiative: builder.query({
      query: (initiativeId) => queryEndPoints.generateMatchingMatrixForInitiative(initiativeId)
    })
  })
});

export const {
  useAddInitiativesMutation,
  useAddInitiativesReactionMutation,
  useGetInitiativeMembersQuery,
  useGetInitiativeAdministratorsQuery,
  useGetInitiativeOwnerQuery,
  useDeleteInitiativeMemberMutation,
  useGetInitiativesByIdQuery,
  useUpdateInitiativesMutation,
  useGetAllInitiativesQuery,
  useLazyGetAllInitiativesQuery,
  useLazyGetAllUserQuery,
  useGetAllInitiativeCommentsQuery,
  useAddInitiativeCommentMutation,
  useDeleteInitiativeCommentMutation,
  useUpdateInitiativeCommentsMutation,
  useLazyJoinInitiativesRequestQuery,
  useJoinInitiativesStatusQuery,
  useInitiativesTabsCountQuery,
  useGetInitiativeActivityLogsQuery,
  useGetInitiativeReactionsQuery,
  useUserMessageToOwnerMutation,
  useAddWatchedInitiativesMutation,
  useRemoveWatchedInitiativesMutation,
  useGetAllLinkedinInitiativeQuery,
  useGetRelatedInitiativesQuery,
  useLinkedAnInitiativeMutation,
  useUnLinkedAnInitiativeMutation,
  useGetRecommendedNetworkQuery,
  useGetMyInitiativesQuery,
  useLeaveInitiativeMutation,
  useCheckInitiativeInWatchedListQuery,
  useCheckUserInAuthorityQuery,
  useLazyGenerateMatchingMatrixForInitiativeQuery
} = initiativesApi;
