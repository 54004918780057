import imageCompression from 'browser-image-compression';
import globalConstant from './constants/globalConstant';

const maxFileSizeInMB = 0.05; // max 50kb

export const compressImage = async (image) => {
  const compressedImage = await imageCompression(image, {
    maxSizeMB: maxFileSizeInMB,
    maxWidthOrHeight: 400
  });
  const fileTypeComressedImage = new File([compressedImage], image.name, {
    type: image.type || 'image/png'
  });
  return fileTypeComressedImage;
};

export const imagePathResolver = (path) => {
  if (path) {
    return `${process.env.REACT_APP_BASE_API}${globalConstant.IMAGE_PREFIX}/${path}`;
  }
  return '';
};
